<template>
  <div class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1">
    <div>
      <div class="p-2 ">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)' || currentUser.courtier_user[0].isPrincipal === 1"
            :aria-expanded="String(isEventHandlerSidebarActive)"
            aria-controls="sidebar-add-new-event"
            block
            variant="primary"
            @click="$emit('update:isEventHandlerSidebarActive', true)"
        >
          Nouveau
        </b-button>
      </div>

      <!-- Filters -->
      <b-list-group class="mt-2" flush>
        <!--        <b-list-group-item
                    v-for="(checker, index) in optionsRadio" v-if="can(checker.privilege) || currentUser.courtier_user[0].isPrincipal === 1"
                    :key="index"
                    :active="checkAllUsers === checker.value"
                    :disabled="checkAllUsers === checker.value"
                    button
                    @click="checkAllUsers = checker.value"
                >
                  <feather-icon :icon="checker.icon" class="mr-75" size="18"/>
                  <span class="align-text-bottom line-height-1">{{ checker.text }}</span>
                </b-list-group-item>-->
        <b-list-group-item :active="checkAllUsers === 'Own'"
                           button
                           :disabled="checkAllUsers === 'Own'"
                           @click="checkAllUsers = 'Own'"
                      >
          <feather-icon class="mr-75" icon="UserIcon" size="18"/>
          <span class="align-text-bottom line-height-1">Moi</span>
        </b-list-group-item>
        <b-list-group-item v-if="can('Access to agenda of broker') || currentUser.courtier_user[0].isPrincipal === 1"
                           :active="checkAllUsers === 'All'"
                           button
                           :disabled="checkAllUsers === 'All'"
                           @click="checkAllUsers = 'All'"
        >
          <feather-icon class="mr-75" icon="GlobeIcon" size="18"/>
          <span class="align-text-bottom line-height-1">Tous</span>
        </b-list-group-item>
      </b-list-group>

      <div class="p-2">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">Calendriers</span>
        </h5>

        <b-form-checkbox
            v-model="checkAll"
            class="mb-1"
        >
          Voir tout
        </b-form-checkbox>
        <b-form-group class="ml-1">
          <b-form-checkbox-group
              v-model="selectedCalendars"
              name="event-filter"
              stacked
          >
            <b-form-checkbox
                v-for="item in calendarOptions"
                :key="item.label"
                :class="`custom-control-${item.color}`"
                :value="item.label"
                class="mb-1"
                name="event-filter"
            >
              {{ item.label }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </div>

    </div>
    <!-- <b-img :src="require('@/assets/images/pages/calendar-illustration.png')"/> -->
    <b-img :src="require('@/assets/images/bg-calendar.png')" height="140" width="140" class="ml-auto mr-auto mb-2" fluid  />
  </div>
</template>

<script>
import { BButton, BFormCheckbox, BFormCheckboxGroup, BFormGroup, BImg, BListGroup, BListGroupItem } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useCalendarSidebar from './useCalendarSidebar'

export default {
  directives: {
    Ripple,
  },
  components: {
    BListGroup,
    BListGroupItem,
    BButton,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
      currentUser: JSON.parse(localStorage.getItem('userData')),
      optionsRadio: [
        {
          text: 'Moi',
          value: 'Own',
          privilege: 'Access to my agenda',
          icon: 'UserIcon'
        },
        {
          text: 'Tous',
          value: 'All',
          privilege: 'Access to agenda of broker',
          icon: 'GlobeIcon'
        },
      ],
    }
  },
  setup() {
    const {
      calendarOptions,
      selectedCalendars,
      checkAll,
      checkAllUsers
    } = useCalendarSidebar()

    return {
      calendarOptions,
      selectedCalendars,
      checkAll,
      checkAllUsers
    }
  },
}
</script>
<style lang="scss" scoped>
.list-group .list-group-item {
  padding: 0.58rem 1.5rem;
  border: 0;
  font-weight: 500;
  letter-spacing: 0.4px;
  border-left: 2px solid transparent;
  border-radius: 0;

  + .list-group-item.active {
    margin-top: 0;
  }

  &:hover,
  &:focus,
  &.active {
    background: transparent;
    color: #7367F0FF;
  }

  &.active {
    border-color: #7367f0;
  }
}
</style>
